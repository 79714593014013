/**
 * Created by Araja Jyothi Babu on 22-Oct-16.
 */
import moment from 'moment';
import $ from 'jquery';
import {
    USP_APIS, EVENT_TYPE_ENUM, LAUNCH_NODE_ID,
    INCIDENTS_ENUM, USER_GROUP_FILTERS_CONSTANTS,
    OTHERS_KEY, SERVER_HOSTS, SERVER_IDENTIFIERS
} from '../constants';
import { API_QUERY_PARAMS, MATTERMOST_DASHBOARDS_EVENTS_API } from '../constants/EndPoints';
import logger from './Logger';
import {APP_ID_FOR_DEMO, DEMO_APP_ID, IOS_DEVICE_MAP} from '../config';
import {
    yellow, green, orange, purple, red, blue, brown, teal, grey, amber,
    cyan, deepOrange, deepPurple, indigo, pink, lime, blueGrey, lightGreen
} from '@material-ui/core/colors';

/**
 * Converts first order Maps to JSON objects
 * @param map
 * @returns {{}}
 */
export const mapToJson = (map) => { //FIXME: handle large maps
    let mappedArray = JSON.parse(JSON.stringify([...map]));
    let json = {};
    mappedArray.forEach(item => {
        json[item[0]] = item[1];
    });
    return json;
};

/**
 * returns a App with AppId from given AppsList
 * @param apps
 * @param appId
 * @returns {*}
 */
export const appFromList = (apps, appId) => {
    if(apps === null || typeof apps === 'undefined' || apps.length === 0)
        return null;
    for(let i = 0; i < apps.length; i++) {
        if (apps[i].app_id === appId)
            return apps[i];
    }
    logger.error("No App found for appId: ", appId);
    return null;
};

/**
 * checks whether a value is defined
 * @param value {*}
 * @param strict {Boolean}
 * @returns {Boolean}
 */
export function isDefined(value, strict = true){
    if(!strict && (value === 0 || value === "")) return true; //FIXME: handling 0 values
    return value && value !== null && typeof value !== 'undefined';
}

/**
 * checks whether a property exists and if the value is defined
 * @param object {Object}
 * @param property {String}
 * @returns {Boolean}
 */
export function hasAndIsDefined(object, property) {
    return object.hasOwnProperty(property) && isDefined(object[property]);
}

/**
 * assigns new values if existing value is null or undefined
 * @param defaultValue
 * @param newValue
 * @returns {*}
 */
export function assignValue(defaultValue, newValue) {
    return isDefined(newValue) ? newValue : defaultValue;
}

/**
 * replaces unwanted chars from string
 * @param text
 * @param originalChar
 * @param newChar
 * @param defaultValue
 * @returns {*}
 */
export function removeCharsWith(text, originalChar, newChar, defaultValue = "") {
    if(!isDefined(text)) return defaultValue;
    return text.split(originalChar).join(newChar);
}

/**
 * Capitalizes each word in string
 * @param text
 * @param onlyFirstWord
 * @returns {string}
 */
export function capitalizeEachWord(text, onlyFirstWord = false) {
    if(!isDefined(text)) return "";
    if(onlyFirstWord) return toTitleCase(text);
    return text.split(" ").map(word => toTitleCase(word)).join(" ");
}

/**
 *
 * @param key
 * @param map
 */
export function getTitle(key, map){
    let newItem = {};
    for(let index=0; index < map.length; index++){
        if(map[index].key === key){
            return map[index];
        }
    }
    newItem.visibleName = removeCharsWith(key, "_", " ");
    newItem.metric = "";
    return newItem;
}

/**
 * Gives random color as HEX String
 * @returns {string}
 */
export function getRandomColor(isDark = false) {
    let letters = isDark ? '345678' : 'A56789';
    let color = '#';
    for (let i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
    //return '#'+(Math.random()*0xFFFFFF<<0).toString(16); generates any color
}

/**
 * return data with random color
 * @param data "Array of Objects"
 * @returns {*}
 */
export function dataWithColoredSegments(data){
    return data.map((item, index) => {
        item.fill = randomColorWithIndex(index);
        return item;
    })
}

/**
 * returns URL with appId and userId as QueryStrings
 * @param url
 * @param auth
 * @param appId
 * @returns {string}
 */
export function makeDefaultQueryString(url, auth, appId){
    let withQP = `${url}?${API_QUERY_PARAMS.customerId}=${auth.user.email}`;
    if(isDefined(appId)){
        withQP += `&${API_QUERY_PARAMS.appId}=${changeForDemoApp(url, appId)}`;
    }
    return withQP;
}

/**
 * return group id from list of groups
 * @param groups
 * @param groupType
 * @returns {*}
 */
export function getGroupIdFromList(groups, groupType){
    if(groups === null || typeof groups === 'undefined' || groups.length === 0)
        return null;
    for(let i = 0; i < groups.length; i++) {
        if(groups[i].group_type === groupType){
            return groups[i]._id;
        }
    }
    logger.error("No groupId found from given Groups for groupType:", groupType);
    return null;
}

/**
 * return path id from list of paths
 * @param paths
 * @param pathName
 * @returns {*}
 */
export function getPathIdFromList(paths, pathName){
    if(paths === null || typeof paths === 'undefined' || paths.length === 0)
        return null;
    for(let i = 0; i < paths.length; i++){
        if(paths[i].name === pathName)
            return paths[i]._id;
    }
    logger.error("No pathId found from given Paths for pathName:", pathName);
    return null;
}

/**
 *
 * @param paths
 * @param pathId
 * @returns {null}
 */
export function getPathNameFromList(paths, pathId){
    if(paths === null || typeof paths === 'undefined' || paths.length === 0)
        return null;
    for(let i = 0; i < paths.length; i++){
        if(paths[i]._id === pathId)
            return paths[i].name;
    }
    logger.error("No pathId found from given Paths for pathName:", pathId);
    return null;
}

/**
 *
 * @param groups
 * @param groupId
 * @returns {*}
 */
export function getGroupNameFromList(groups, groupId){
    // logger.info(groups, groupId);
    if(groups === null || typeof groups === 'undefined' || groups.length === 0)
        return null;
    for(let i = 0; i < groups.length; i++) {
        if(groups[i]._id === groupId){
            // logger.info("GroupName found ", groups[i].group_name || groups[i].name);
            return groups[i].group_name || groups[i].name;
        }
    }
    // logger.error("No groupName found from given Groups for groupId:", groupId);
    return "No Name";
}

/**
 *
 * @param groups
 * @param groupId
 * @returns {*}
 */
export function getGroupTypeFromList(groups, groupId){
    if(groups === null || typeof groups === 'undefined' || groups.length === 0)
        return null;
    for(let i = 0; i < groups.length; i++) {
        if(groups[i]._id === groupId){
            return groups[i].group_type;
        }
    }
    // logger.error("No groupType found from given Groups for groupId:", groupId);
    return null;
}

/**
 *
 * @param groups
 * @param groupId
 * @returns {*}
 */
export function getGroupFromList(groups, groupId){
    if(groups === null || typeof groups === 'undefined' || groups.length === 0)
        return null;
    for(let i = 0; i < groups.length; i++) {
        if(groups[i]._id === groupId){
            return groups[i];
        }
    }
    // logger.error("No group found from given Groups for groupId:", groupId);
    return null;
}

/**
 * return USP API
 * @param uspType
 */
export function makeUSPAPI(uspType) {
    for(let key in USP_APIS){
        if(USP_APIS.hasOwnProperty(key)){
            if(uspType.indexOf(key) !== -1)
                return USP_APIS[key];
        }
    }
}

/**
 *
 * @param defaultSortIndexes
 * @param sortedDataList
 * @param columnKey
 * @param sortDir
 * @param SortTypes
 * @returns {*}
 */
export function indexSorter(defaultSortIndexes, sortedDataList, columnKey, sortDir, SortTypes){
    let sortIndexes = defaultSortIndexes.slice();
    return sortIndexes.sort((indexA, indexB) => {
        let valueA = sortedDataList[indexA][columnKey];
        let valueB = sortedDataList[indexB][columnKey];
        let sortVal = 0;
        if (valueA > valueB) {
            sortVal = 1;
        }
        if (valueA < valueB) {
            sortVal = -1;
        }
        if (sortVal !== 0 && sortDir === SortTypes.ASC) {
            sortVal = sortVal * -1;
        }
        return sortVal;
    });
}

/**
 *
 * @param distributions
 * @returns {*}
 */

/**
 * return time from now
 * @param time
 * @returns {*}
 */
export function displayTimeFromNow(time) { //TODO: use this function to all
    if(!time || time === "NA") return "NA";
    return moment(time).fromNow();
}

/**
 *
 * @param n
 * @returns {boolean}
 */
export function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

/**
 * rounds large float values to given digits
 * @param number
 * @param toDigits
 * @param defaultNumber
 * @returns {*}
 */
export const roundOffNumber = (number, toDigits = 2, defaultNumber = 0) => {
    return isNaN(number) ? defaultNumber : (isFloat(number) ? Number(number.toFixed(toDigits)) : number);
};

export const toTimeSpent = (number, toDigits = 2) => {
    function inTime(n, text) {
        return roundOffNumber(number / n, toDigits) + ` ${text} `;
    }
    switch (true){
        case number / 86400 > 1 : return inTime(86400, "Days");
        case number / 3600 > 1 : return inTime(3600, "Hours");
        case number / 60 > 1 : return inTime(60, "Minutes");
        default: return inTime(1, "Seconds");
    }
};

/**
 * Capitalize
 * @param str
 */
export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

export function keyToTitle(str = "") {
    return str.split("_").map(w => toTitleCase(w)).join(" ");
}

/**
 * CSV or XLS downloader for JSON data
 * @param jsonData
 * @param reportTitle
 * @param showLabel
 * @param fileType either CSV or XLS
 */
export function downloadJSONasCSVorXLS(jsonData, reportTitle, showLabel = true, fileType = "csv") {
    let arrData = typeof jsonData !== 'object' ? JSON.parse(jsonData) : jsonData;
    let CSV = '';
    CSV += reportTitle + '\r\n\n';
    if (showLabel) {
        let row = "";
        for (let index in arrData[0]) {
            row += toTitleCase(removeCharsWith(index, '_', ' ')) + ',';
        }
        row = row.slice(0, -1);
        CSV += row + '\r\n';
    }
    for (let i = 0; i < arrData.length; i++) {
        let row = "";
        for (let index in arrData[i]) {
            row += `"${typeof arrData[i][index] === "string" ? arrData[i][index].replace(/,/g, "") : arrData[i][index]}",`;
        }
        row.slice(0, row.length - 1);
        CSV += row + '\r\n';
    }
    if (CSV === '') {
        alert("Invalid data");
        return;
    }
    let fileName = reportTitle.replace(/ /g,"_");
    downloadAsFile(CSV, fileName, fileType);
}

/**
 * downloads JSON object as JSON file
 * @param JSONData
 * @param fileName
 */
export function downloadJSONAsJSONFile(JSONData, fileName){
    downloadAsFile(JSON.stringify(JSONData, null, 2), fileName, "json");
}

export const downloadAsFile = (data, fileName, extension) => {
    let uri = 'data:text/' + extension + ';charset=utf-8,' + escape(data);//encodeURIComponent(data);
    let link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + "." + extension;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 *
 * @param blob
 * @param callback
 */
export function convertBlobToBase64(blob, callback) {
    let reader = new FileReader();
    reader.onload = function() {
        let dataUrl = reader.result;
        let base64 = dataUrl.split(',')[1];
        if(typeof callback === 'function') {
            callback(base64);
        }
    };
    reader.readAsDataURL(blob);
}

/**
 *
 * @param time
 * @param timestamp
 * @param defaultValue
 * @return as format of hh:mm AM
 */
export function formatWithAddedTime(time, timestamp, defaultValue = "NA") {
    if(!isDefined(time, false) || !isDefined(timestamp)) return defaultValue;
    return moment(timestamp).add(time, 'seconds').format('LT');
}

/**
 *
 * @param timestamp
 * @param defaultValue
 * @return as format of hh:mm AM
 */
export function formatToTime(timestamp, defaultValue = "NA") {
    if(!isDefined(timestamp)) return defaultValue;
    return moment(timestamp).format('LT');
}

/**
 *
 * @param timestamp
 * @param formats
 * @param defaultValue
 * @returns {string}
 */
export function calendarTime(timestamp, formats = { sameElse: 'DD/MM/YYYY' }, defaultValue = "NA"){
    if(!isDefined(timestamp)) return defaultValue;
    return moment(timestamp).calendar(null, formats);
}

/**
 *
 * @param timestamp
 * @param format
 * @param defaultValue
 * @returns {*}
 */
export function formatTime(timestamp, format = "MMM Do YYYY, h:mm:ss a", defaultValue = "NA"){
    if(!isDefined(timestamp)) return defaultValue;
    return moment(timestamp).format(format);
}

/**
 *
 * @param timeline
 * @returns {null}
 */
export function stackedTimeline(timeline){
    if(timeline && timeline.length > 0) {
        const isFirstItemEvent = timeline[0].item_type !== EVENT_TYPE_ENUM.NAVIGATION_ITEM;
        let stackedTimeline = timeline.filter((item, index) => { //getting all navigation items
            return item.item_type === EVENT_TYPE_ENUM.NAVIGATION_ITEM;
        });
        let navigationItemIndex = -1;
        if(isFirstItemEvent){ //adding unknown navigation item if first item is event
            const unKnownNavigationItem = {name: "Unknown View"}; //FIXME: need fix from server itself
            stackedTimeline.splice(0, 0, unKnownNavigationItem);
            navigationItemIndex = 0;
            stackedTimeline[0]['events'] = [];
        }
        timeline.forEach((item, index) => {
            if(item.item_type !== EVENT_TYPE_ENUM.NAVIGATION_ITEM){
                if(item.item_type === EVENT_TYPE_ENUM.ISSUE_ITEM){ //if contains issueItem
                    stackedTimeline[navigationItemIndex]["issue"] = item;
                }
                stackedTimeline[navigationItemIndex]['events'].push(item);
            }else{
                stackedTimeline[++navigationItemIndex]['events'] = [];
            }
        });
        return stackedTimeline;
    }else{
        return []
    }
}

/**
 *
 * @param {Array} list
 * @param {string} defaultValue
 * @returns {string}
 */
export function getHighestFromList(list, defaultValue = "NA"){
    if(!list || !Array.isArray(list)) return defaultValue;
    return list.sort().reverse()[0];
}

/**
 *
 * @param variable
 * @returns {boolean}
 */
function isString(variable){
    return isDefined(variable) && typeof variable === 'string';
}
/**
 *
 * @param variable
 * @returns {boolean}
 */
function isArray(variable) {
    return isDefined(variable) && Array.isArray(variable);
}

/**
 *
 * @param variable
 * @returns {*|boolean|boolean}
 */
function isNumber(variable) {
    return isDefined(variable, false) && !isNaN(variable);
}

/**
 * One more Server Scrap
 * @param key
 * @returns {*}
 */
function normalizeAttributeKeys(key){ //server query params
    if(USER_GROUP_FILTERS_CONSTANTS.hasOwnProperty(key)){
        switch(key){
            case USER_GROUP_FILTERS_CONSTANTS.app_versions:
                return "versions";
            case USER_GROUP_FILTERS_CONSTANTS.os_versions:
                return "os_version";
            case USER_GROUP_FILTERS_CONSTANTS.acquisition_sources:
                return "acquisition";
            case USER_GROUP_FILTERS_CONSTANTS.location:
                return "country";
            default:
                return key;
        }
    }else{
        return key;
    }
}

/**
 * make query Strings for API
 * @param queryObject
 * @returns {string}
 */
export function withQueryStrings(queryObject = {}){
    let queryStrings = "";
    if(isDefined(queryObject, false)) {
        for (let query in queryObject) {
            if (queryObject.hasOwnProperty(query)) {
                logger.debug(query, queryObject[query]);
                if (isArray(queryObject[query])) {
                    queryStrings += queryObject[query].map(item => `&${normalizeAttributeKeys(query)}=${item}`).join('');
                    logger.debug(query, queryObject[query], queryStrings);
                } else if (isString(queryObject[query]) || isNumber(queryObject[query])) {
                    queryStrings += `&${query}=${queryObject[query]}`;
                } else { //FIXME: Not sure of this
                    //logger.debug("Undefined Key", query, "for Object", queryObject);
                    queryStrings += withQueryStrings(queryObject[query]);
                }
            }
        }
    }else{
        //logger.debug("Undefined Keys", queryObject);
    }
    return queryStrings;
}

/**
 * Assign index to each Node in Graph
 * @param nodes
 * @returns {{}}
 */
function getOrderedMap(nodes){
    let orderedMap = {};
    let indexer = 1;
    nodes.forEach(node => {
        orderedMap[node._id] = node.node_id !== LAUNCH_NODE_ID ? indexer++ : 0;
    });
    return orderedMap;
}

/**
 * Generates ApxorUsageGraph friendly data
 * @param nodes {Array}
 * @returns {{nodes: Array, links: Array, maxWeight: number, usageOverview: {Crashes: number, Hangs: number, Sessions: number}}}
 */
export function getGraphModel(nodes){
    if(!isArray(nodes)) return {}; //sanity check
    const orderedMap = getOrderedMap(nodes);
    let visits = 0, visitedUsers = 0, maxWeight = 0, totalCrashes = 0, totalHangs = 0, totalSessions = 0;
    let finalNodes = [], finalLinks = [];
    nodes.forEach(node => {
        node.connections.adjacency_node_list.forEach(adjacentNode => {
            visits += adjacentNode.visits;
            visitedUsers += adjacentNode.user_count;
            finalLinks.push({ //generating links for each node
                source : orderedMap[node._id],
                target : orderedMap[adjacentNode._id],
                weight : adjacentNode.visits,
                user_count : adjacentNode.user_count
            });
            maxWeight = maxWeight < adjacentNode.visits ? adjacentNode.visits : maxWeight;
        });
        finalNodes.push({ //generating nodes
            id : node._id,
            nodePath: node.node_id,
            index : orderedMap[node._id],
            title : node.title,
            src : node.image,
            state : node.criticality_level,
            data : node.issue_data,
            visits: visits,
            visited_users: visitedUsers,
            session_ends: node.connections.session_dropped,
            dropped_users: node.connections.users_dropped,
            adjacency: {}, //handling no data
            dropped: {} //handling no data
        });
        totalSessions += node.connections.session_dropped;
        totalCrashes += node.issue_data[INCIDENTS_ENUM.ApplicationCrash] || 0;
        totalHangs += node.issue_data[INCIDENTS_ENUM.ApplicationHangedError] || 0;
    });

    finalNodes.sort((firstNode, secondNode) => firstNode.index - secondNode.index); //sorting ascending nodes based on index

    const usageOverview = { //FIXME: better have enum for keys
        Crashes: totalCrashes,
        Hangs: totalHangs,
        Sessions: totalSessions
    };
    return { //GraphModel
        nodes: finalNodes,
        links: finalLinks,
        maxWeight: maxWeight,
        usageOverview: usageOverview
    };
}

/**
 *
 * @param path {string}
 * @param exclusionContent {Array}
 * @returns {boolean|Boolean}
 */
export function isDateFilterApplicable(path, exclusionContent) {
    return isArray(exclusionContent) && isDefined(path) && exclusionContent.every(endPoint => !Boolean(path.match(endPoint)));
}

/**
 *
 * @param path
 * @param inclusionContent
 * @returns {boolean|Boolean}
 */
export function isDateFilterDisabled(path, inclusionContent) {
    return isArray(inclusionContent) && isDefined(path) && inclusionContent.some(endPoint => Boolean(path.match(endPoint)));
}

/**
 *
 * @param issueType {string}
 * @returns {string}
 */
export function getIssueVisibleName(issueType){
    switch(issueType){
        case INCIDENTS_ENUM.ApplicationCrash:
            return "Crashes";
        case INCIDENTS_ENUM.APXCustomError:
            return "Functional Errors";
        case INCIDENTS_ENUM.ApplicationHangedError:
            return "ANRs";
        case INCIDENTS_ENUM.SlowResponsivenessError:
            return "Slow Responses";
        case INCIDENTS_ENUM.HeavyCPUUsageErrorLevel1:
        case INCIDENTS_ENUM.HeavyCPUUsageErrorLevel2:
            return "CPU";
        case INCIDENTS_ENUM.NotInteractableError:
            return "Not Interactable Error";
        case INCIDENTS_ENUM.UndoObserver:
            return "Undo";
        case INCIDENTS_ENUM.LaunchTimeError:
            return "Launch Time Errors";
        case INCIDENTS_ENUM.MemorySpikeError:
        case INCIDENTS_ENUM.MemorySpikeWarning:
        case INCIDENTS_ENUM.MemoryLowErrorLevel1:
        case INCIDENTS_ENUM.MemoryLowErrorLevel2:
            return "Memory";
        default:
            return issueType;
    }
}

/**
 *
 * @param event
 * @param message
 * @param appId
 * @param appName
 * @param user
 * @param location
 */
export function sendEventAsMessage(event, message, appId, appName, user, location) {
    try { //For dashboard sanity
        const messageBody = {};
        const { city, country_name, country, region, ip } = location;
        const fromMessage = ` for app **${appName}** (_${appId}_) from ${city || "UnKnown City"}(${country_name || country}) (_${region}_) with IP: ${ip}`;
        messageBody.username = user;
        messageBody.text = `**${event}:**\n${message}${fromMessage}`;
        messageBody.icon_url = window.location.origin + "/assets/img/ApxorLogo.png"; //FIXME: might change in future
        $.ajax({
            type: "POST",
            url: MATTERMOST_DASHBOARDS_EVENTS_API,
            dataType: 'text',
            async: true,
            data: JSON.stringify(messageBody),
            contentType: "application/json; charset=utf-8",
            success: function (data) {
                logger.info("Message sent successfully..!")
            },
            error: function (xhr, status, err) {
                logger.error("Message sending failed..!");
            }
        });
    }catch(e){
        logger.error("Failed to send event Message", e);
    }
}

/**
 *
 * @param data
 * @returns {*}
 */
export function makeDonutData(data) {
    if(isArray(data)) {
        const remaining = data.slice(9);
        const topData = [...data.slice(0, 9)];
        if(data.length > 9){
            topData.push({
                key: OTHERS_KEY,
                value: remaining.reduce((a, b) => a + b.value, 0)
            });
        }
        return {
            mainData: dataWithColoredSegments(topData), //top 10
            remainData: remaining
        }
    }else {
        return {
            mainData: [],
            remainData: []
        }
    }
}

/**
 * Header for excel data
 * @param data
 * @returns {string}
 */
export function buildHeader(data){
    let rows = ["", ""];
    for(let key in data){
        if(data.hasOwnProperty(key)) {
            if(Array.isArray(data[key])){
                for(let subKey in data[key][0]){
                    if(data[key][0].hasOwnProperty(subKey)) {
                        rows[1] += toTitleCase(removeCharsWith(subKey, '_', ' ')) + '\t,';
                        rows[0] += key + '\t,';
                    }
                }
            }else {
                rows[0] += key + '\t,';
                rows[1] += key.replace(/./g, " ") + '\t,';
            }
        }
    }
    rows[0] = rows[0].split(",").map((key, index, thisRow) => thisRow.indexOf(key) === index ? key : "").join(",");
    return rows[0].slice(0, rows[0].length - 1) + '\r\n' + rows[1].slice(0, rows[1].length - 1) + '\r\n';
}

export function downloadAsXLS(JSONData, ReportTitle, showLabel, fileType = "xls") {
    let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;
    let CSV = '';
    CSV += ReportTitle + '\r\n\n';
    if(showLabel){
        CSV = buildHeader(arrData[0]);
    }
    const cols = 8; //FIXME: crap hardcoded
    let rows = [], rowIndex = 0, subRowIndex = 0, colIndex = 0, subColIndex = 0;
    arrData.forEach((row, index) => {
        rows[rowIndex] = new Array(cols).fill("");
        colIndex = 0;
        for(let key in row){
            subRowIndex = rowIndex;
            if(row.hasOwnProperty(key)) {
                if (Array.isArray(row[key])) {
                    for(let subRowIndex = 0; subRowIndex < row[key].length; subRowIndex++){
                        const subRow = row[key][subRowIndex];
                        subColIndex = colIndex;
                        for(let subKey in subRow){
                            if(subRow.hasOwnProperty(subKey)){
                                rows[subRowIndex][subColIndex++] = subRow[subKey];
                            }
                        }
                        if(!Array.isArray(rows[subRowIndex + 1])){
                            rows[++subRowIndex] = new Array(cols).fill(""); //creating new Array if not exists already
                        }else{
                            subRowIndex++;
                        }
                    }
                    colIndex = subColIndex;
                }else{
                    rows[rowIndex][colIndex++] = row[key];
                }
            }
        }
        rowIndex = subRowIndex++;
    });
    CSV += rows.map(row => row.slice(0, row.length -1)).join("\r\n");
    if (CSV === '') {
        alert("Invalid data");
        return;
    }
    let fileName = ReportTitle.replace(/ /g,"_");
    let uri = 'data:text/' + fileType + ';charset=utf-8,' + escape(CSV);
    let link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + "." + fileType;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 *
 * @param anyNumber
 * @param fixed
 * @returns {*}
 */
export function formatNumber(anyNumber, fixed = 2) {
    if(isNaN(anyNumber)) return anyNumber;
    const number = roundOffNumber(anyNumber); //rounding number to 2 decimals
    return Math.abs(Number(number)) >= 1.0e+9

        ? (Math.abs(Number(number)) / 1.0e+9).toFixed(fixed) + " B"
        // Six Zeroes for Millions
        : Math.abs(Number(number)) >= 1.0e+6

            ? (Math.abs(Number(number)) / 1.0e+6).toFixed(fixed) + " M"
            // Three Zeroes for Thousands
            : Math.abs(Number(number)) >= 1.0e+3

                ? (Math.abs(Number(number)) / 1.0e+3).toFixed(fixed) + " K"

                : Math.abs(Number(number));
}

/**
 *
 * @param a
 * @param b
 * @returns {boolean}
 */
export function areArraysStrictEqual(a, b) { //TODO: make this gloabl for entire JSON
    if(Array.isArray(a) && Array.isArray(b)){
        if(a.length === b.length){
            for(let i = 0; i < a.length; i++){
                if(a[i] !== b[i]){
                    return false;
                }
            }
            return true;
        }else{
            return false;
        }
    } else {
        return false;
    }
}

/**
 *
 * @param hostName
 * @returns {string}
 */
export function getHost(hostName = SERVER_HOSTS.google) {
    switch(hostName){
        case SERVER_HOSTS.azure : return SERVER_IDENTIFIERS.azure
        default : return SERVER_IDENTIFIERS.google
    }
}

/**
 *
 * @param device
 * @returns {*}
 */
export function readableDeviceName(device) {
    return IOS_DEVICE_MAP.hasOwnProperty(device) ? IOS_DEVICE_MAP[device] : device;
}

/**
 *
 * @param percent
 * @param color
 * @returns {string}
 */
export function shadeWithColor(percent, color = "#3f83a3") {
    const rate = 1.0 - Math.ceil(percent / 10) / 10;
    const f = parseInt(color.slice(1), 16),
        t = rate < 0 ? 0 : 255,
        p = rate < 0 ? rate * -1 : rate,
        R = f >> 16,
        G = (f >> 8)& 0x00FF,
        B = f & 0x0000FF;
    return `#${
        (
            0x1000000 +
            (Math.round((t - R) * p) + R) * 0x10000 +
            (Math.round((t - G) * p) + G) * 0x100 +
            (Math.round((t - B) * p) + B)
        ).toString(16).slice(1)
        }`;
}

/**
 *
 * @param hex
 * @param a
 * @param defaultHex
 * @returns {string}
 */
export const hexToRGBA = (hex, a = 0.5, defaultHex = hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})` : defaultHex;
};

export function rgbToHEX(rgbA){
    const rgb = rgbA.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
        ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : rgbA;
}
/**
 *
 * @param id
 */
export function goToByScroll(id) {
    const domEl = $("#" + id);
    if(domEl.offset()) {
        $("html, body").animate({scrollTop: domEl.offset().top - 60}, 'slow');
    }
}

const AVG_PREFIX = "avg_";

/**
 *
 * @param data
 * @param valueKey
 * @returns {Array}
 */
export function addedWithAverageKey(data, valueKey = null) {
    if(!Array.isArray(data)) return [];
    const average = data.reduce((a, b) => a + b.value, 0) / data.length;
    return data.map(item => {
        let obj = {...item};
        obj[AVG_PREFIX + valueKey] = average.toFixed(2); //for calculating value
        if(valueKey) {
            obj[valueKey] = item.value;
            delete obj.value;
        }
        return obj;
    });
}

export function addedWithAverageKeys(data, valueKeys = []) {
    if(!Array.isArray(data)) return [];
    const initialObj = valueKeys.reduce((a, b) => {a[AVG_PREFIX + b] = 0; return a}, {});
    const averageObj = data.reduce((a, b) => {
        valueKeys.forEach(key => {
            a[AVG_PREFIX + key] += b[key];
        });
        return a;
    }, initialObj);
    for(let key in averageObj){
        if(averageObj.hasOwnProperty(key)){
            averageObj[key] = roundOffNumber(averageObj[key] / data.length);
        }
    }
    return data.map(o => ({...o, ...averageObj}));
}

/**
 *
 * @param all
 * @param event
 * @param valueKey
 * @returns {*}
 */
export function mergeTimeSeries(all, event, valueKey) {
    if(!Array.isArray(all) || !Array.isArray(event)) return [];
    event = addedWithAverageKey([...event], valueKey);
    //all = addedWithAverageKey([...all], "All Avg", "All");
    if(all.length === 0 || all.length !== event.length) return event;
    return all.map((item, index) => {
        for(let key in event[index]){
            if(event[index].hasOwnProperty(key) && key !== "key"){
                item[key] = event[index][key];
            }
        }
        console.info(item);
        return item;
    });
}

/**
 *
 * @param seq
 * @param defaultKey
 * @param valueKey
 * @param emptyValue
 * @param skip
 * @returns {*}
 */
export function appendEmptyValueForSequence(seq = [], defaultKey, valueKey, emptyValue = null, skip = 1){
    if(Array.isArray(seq) && seq.length > 0){
        const upperThreshold = seq[seq.length - 1][defaultKey] + 1;
        const obj = seq.reduce((a, b) => {
            a[b[defaultKey]] = b[valueKey];
            return a;
        }, {});
        return [...Array(upperThreshold).keys()].slice(skip).map(i => ({[defaultKey]: i, [valueKey]: obj[i] || emptyValue}));
    }else{
        return seq;
    }
}

export function mergeSequences(obj, baseKey, valueKey, emptyValue = null) {
    const keys = Object.keys(obj);
    if(keys.length === 0) return [];
    const objOfObj = {...obj};
    for(let key in obj){
        if(obj.hasOwnProperty(key)){
            objOfObj[key] = objOfObj[key].reduce((a, b) => {
                a[b[baseKey]] = b[valueKey];
                return a;
            }, {});
        }
    }
    const upperThreshold = Math.max.apply(null, keys.map(key => obj[key][obj[key].length - 1][baseKey])) + 1;
    if(!isNaN(upperThreshold) && upperThreshold > 0) {
        return [...Array(upperThreshold).keys()].slice(1).map(i => {
            return keys.reduce((a, b) => {
                a[b] = objOfObj[b][i] || emptyValue;
                return a;
            }, {[baseKey]: i});
        });
    } else {
      return [];
    }
}

/**
 * returns min max and average of timeSeries of given key
 * @param arr
 * @param key
 * @param defaultKey
 * @returns {{}}
 */
export function minMaxAverageOf(arr = [], key, defaultKey = "key") {
    if(Array.isArray(key)){
        return key.reduce((a, b) => {
            a[b] = minMaxAverageOf(arr, b, defaultKey);
            return a;
        }, {});
    }else{
        const obj = {};
        obj.avg = arr.reduce((a, b) => a + b[key], 0) / arr.length;
        obj.max = arr.reduce((a, b) => Math.max(a, b[key]), 0);
        obj.min = arr.reduce((a, b) => Math.min(a, b[key]), Infinity);
        const minIndex = arr.findIndex(o => o[key] === obj.min);
        const maxIndex = arr.findIndex(o => o[key] === obj.max);
        if(isNumber(minIndex) && minIndex > -1) {
            obj.minKey = arr[minIndex][defaultKey];
        }
        if(isNumber(maxIndex) && maxIndex > -1){
            obj.maxKey = arr[maxIndex][defaultKey];
        }
        return obj;
    }
}

export function copyToClipboard(text = "") {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.value = text;
    input.select();
    document.execCommand("copy");
    input.remove();
}

export function getPercent(of = 0, from = 1, rounded = 2, defaultValue = "NA") {
    if(isNaN(of) || isNaN(from) || from === 0) return defaultValue;
    return roundOffNumber(of / from * 100, rounded);
}

const REMAINING_COLORS = () => {
    const colors = [lime, deepOrange, lightGreen, deepPurple, cyan, pink, indigo, blueGrey];
    const shades = [200, 500, 700, 900];
    return shades.map(shade => colors.map(color => color[shade])).flat(1);
};

const COLORS = [
    blue[500], green[500], orange[400],
    yellow[900], purple[500], teal[500], red[500],
    amber[500], brown[500], grey[500],
    ...REMAINING_COLORS()
];

export function randomColorWithIndex(index, colors = COLORS){
    return colors[index % colors.length];
}

export function onlyAlphaNumericChars(text = "") {
    return text.replace(/[\W]+/g, "");
}

export function uniqueKeyFromFilters(filters = {}) {
    const attributesAsKey = (attributes) => attributes.map(o => [o.name, o.operator, o.value.join("_")].join("_")).join("_");
    const { user = [], session = [], event = [] } = filters;
    const userKey = attributesAsKey(user);
    const sessionKey = attributesAsKey(session);
    const eventKey = event.map(o => [o.name, o.count.value, o.count.operator, attributesAsKey(o.attributes)].join("_")).join("_");
    return [userKey, sessionKey, eventKey].filter(o => o.length > 0).join("_");
}

/**
 *
 * custom dashboard utils
 */

const PREVIOUS_DASHBOARD_KEY = "_custom_dashboard";

/**
 *
 * @returns {Object}
 */
const getPreviouslyOpenedDashboards = () => JSON.parse(window.localStorage.getItem(PREVIOUS_DASHBOARD_KEY) || "{}");

/**
 *
 * @param dashboards
 * @param appId
 * @returns {*}
 */
export const getPreviousDashboard = (dashboards, appId) => {
    const previousOpenedDashboards = getPreviouslyOpenedDashboards();
    const currentAppDashboard = previousOpenedDashboards[appId];
    if(dashboards.includes(currentAppDashboard)) {
        return currentAppDashboard;
    }
    return undefined;
};
/**
 *
 * @param dashboardId
 * @param appId
 */

export const persistCurrentDashboard = (dashboardId, appId) => {
    const previousOpenedDashboards = getPreviouslyOpenedDashboards();
    previousOpenedDashboards[appId] = dashboardId;
    window.localStorage.setItem(PREVIOUS_DASHBOARD_KEY, JSON.stringify(previousOpenedDashboards));
};

/**
 *
 * @param customers
 * @param user
 * @returns {boolean}
 */
export const isUserRestricted = (customers = [], user) => {
    if(user === window.masterEmail){
        return false;
    }
    for (let i = 0; i < customers.length; i++) {
        const { customer_id, limited_access = false } = customers[i];
        if (customer_id === user && limited_access) { //FIXME: Need better evaluation for restriction, may Realm will do
            return true;
        }
    }
    return false;
};

/**
 *
 * @param customers
 * @param user
 * @param dashboards
 * @returns {*}
 */
export const allowedDashboards = (customers = [], user, dashboards = []) => {
    if(isUserRestricted(customers, user)){
        for (let i = 0; i < customers.length; i++) {
            if (customers[i].customer_id === user) {
                const userDashboards = customers[i].dashboards || [];
                return dashboards.filter(d => userDashboards.includes(d._id));
            }
        }
    }else{
        return dashboards;
    }
};

export const isDashboardAllowed = (customers = [], user, dashboardId) => {
    if(isUserRestricted(customers, user)){
        for (let i = 0; i < customers.length; i++) {
            if (customers[i].customer_id === user) {
                const userDashboards = customers[i].dashboards || [];
                return userDashboards.includes(dashboardId);
            }
        }
    }else{
        return true;
    }
};

/**
 *
 * @param arr
 * @returns {string[]}
 */
export const sortArrayByFrequency = (arr = []) => {
    const o = arr.reduce((a, b) => {
        a[b] = a.hasOwnProperty(b) ?a[b] + 1 : 1;
        return a;
    }, {});
    return Object.keys(o).sort((a, b) => o[a] - o[b] > 0 ? -1 : (o[a] - o[b] < 0 ? 1 : 0));
};

/**
 *
 * @param prefix
 * @returns {string}
 */
export const uniqueId = (prefix = "uid") => prefix + '-' + Math.round(Math.random() * 1000000000);

export const sortArrayByKey = (arr = [], key, reverse = false) => {
    const f = reverse ? -1 : 1;
    if(isDefined(key)){
        return arr.sort((a, b) => a[key] > b[key] ? f : (a[key] < b[key] ? -f : 0));
    }else{
        return arr;
    }
};

const DATE_KEY = "Date";

export const graphDataToCSVString = (label, data = [], mainKeyLabel = DATE_KEY, mainKey = "key") => {
    // let str = "\r\n" + label.replace(/"|'/g, ' ').trim() + "\r\n"; //FIXME: careful about regex
    let str = "\r\n";
    function toCSVRow(d, k, l = k) {
        return l + ", " + d.map(o => o[k]).join(", ") + "\r\n";
    }
    if(Array.isArray(data) && data.length > 0){
        const keys = Object.keys(data[0]);
        //appending header
        if(keys.includes(mainKey)){
            if(mainKeyLabel === DATE_KEY) {
                str += mainKeyLabel + ", " + data.map(o => formatTime(o[mainKey], "MMM Do", o[mainKey])).join(", ") + "\r\n";
            }else{
                str += toCSVRow(data, mainKey, mainKeyLabel);
            }
        }
        keys.forEach(key => {
            if(key !== mainKey){
                str += toCSVRow(data, key);
            }
        });
    }
    return str;
};

export const isValidEmail = (text = "") => /\S+@\S+\.\S+/.test(text);

/**
 * Calculating starting of day with IST offset added
 * @param moment
 * @param inUTC
 * @returns {Date}
 */
export const normalizedDate = (moment, inUTC = false) => {
    return moment.utc().toDate().toISOString();
};

export const normalizedDateTime = (moment, inUTC = false) => {
    return moment.utc().toDate().toISOString();
};

export const normalizeFiltersDateTime = (date) => {
    return date.utc().toISOString().slice(0, 23)+"Z"
};

export const shuffledArray = (inputArr = []) => {
    if(Array.isArray(inputArr)){
        const shuffled = inputArr.slice();
        for(let i = shuffled.length - 1; i > 0; i--){
            const rand = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[rand]] = [shuffled[rand], shuffled[i]]; //swapping
        }
        return shuffled;
    }
    return inputArr;
};

/**
 *
 * @param attributes
 * @returns {Array}
 */
export const attributesAsMentions = (attributes) => {
    const mentions = [];
    for(let type in attributes){
        if(attributes.hasOwnProperty(type)){
            attributes[type].forEach(attribute => {
                mentions.push({
                    name: attribute,
                    type
                });
            });
        }
    }
    return mentions;
};

/**
 *
 * @param text
 * @returns {string}
 */
export const replaceMacrosWithDefault = (text = '') => {
    if(isString(text)){
        return text.replace(/\[.+?\((.*?)\)\]/g, '$1'); //FIXME: Have an eye on this, '?' indicates match as less as possible
    }else{
        return text;
    }
};

/**
 *
 * @param arrA
 * @param arrB
 * @returns {*}
 */
export const areArraysEqual = (arrA, arrB) => {
    if(Array.isArray(arrA) && Array.isArray(arrB)){
        return arrA.length === arrB.length && arrA.every(o => arrB.includes(o)) && arrB.every(o => arrA.includes(o));
    }
    else { if (arrA === arrB) return true;}
    return false;
};

/**
 *
 * @param data
 * @param keyToSpill
 * @param days
 * @returns {*}
 */
export const formatRetentionUsage = (data = [], keyToSpill = "percent", days = []) => {
    if(isArray(data)){
        const event_total = "event_total";
        const groupedByEvent = data.reduce((acc, obj) => {
            if(acc.hasOwnProperty(obj.event_name)){
                acc[obj.event_name].push(obj);
            }else{
                acc[obj.event_name] = [obj];
            }
            return acc;
        }, {});
        const spillDays = (eventData = []) => {
            const obj = eventData.reduce((acc, o) => {
                acc["Day " + o.day] = o[keyToSpill];
                acc["avg_Day " + o.day] = o.user_count;
                acc[event_total] = (acc[event_total] || 0) + o.event_count;
                return acc;
            }, {});
            days.forEach(day => { //filling empty days
                if(!obj.hasOwnProperty("Day " + day)){
                    obj["Day " + day] = 0;
                    obj["avg_Day " + day] = 0;
                }
            });
            return {
                ...obj,
                ...eventData[0] && eventData[0]
            }
        };
        return sortArrayByKey(Object.keys(groupedByEvent).map(event => spillDays(groupedByEvent[event])), event_total, true);
    }else {
        return [];
    }
};

/**
 *
 * @param filter
 * @returns {{user: *, session: *}}
 */
export const segmentFilterWithoutCounts = (filter = {}) => {
    const { user = [], session = [] } = filter;
    const reduced = (arr) => {
        return arr.reduce((acc, obj) => {
            acc[obj.name] = obj.value;
            return acc;
        }, {});
    };
    return {
        user: reduced(user),
        session: reduced(session)
    };
};

/**
 *
 * @param data
 * @returns {*}
 */
export const sortedByVersion = (data) => {
    if(Array.isArray(data)){
        const asNumberArr = (v) => v.split('.').map(i => isNumber(parseInt(i)) ? parseInt(i) : 0);
        const comparator = (v1, v2) => { //FIXME: need proper method for semver comparison
            if(v1.length === 0){
                return -1;
            }
            if(v2.length === 0){
                return 1;
            }
            if(v1[0] === v2[0] && v1.length > 1 && v2.length > 1){
                if(v1[1] === v2[1] && v1.length > 2 && v2.length > 2){
                    return v1[2] - v2[2];
                }else{
                    return v1[1] - v2[1];
                }
            }else{
                return v1[0] - v2[0];
            }
        };
        return data.sort((a, b) => comparator(asNumberArr(a.version), asNumberArr(b.version)));
    }else{
        return [];
    }
};

const MUTATION_URL_REGEX = /messages|art-configs|notifications|additional-customers/;

/**
 *
 * @param url
 * @param appId
 * @returns {*}
 */
export const changeForDemoApp = (url = "", appId) => {
    if(appId === DEMO_APP_ID && !MUTATION_URL_REGEX.test(url)){
        return APP_ID_FOR_DEMO;
    }else{
        return appId;
    }
};

/**
 *
 * @param data
 * @param nVersions
 * @param minimumUsers
 * @returns {*}
 */
export const getTopVersionsWithMinimumUsers = (data, nVersions, minimumUsers) => {
    if(Array.isArray(data) && isNumber(nVersions) && isNumber(minimumUsers)){
        return data.slice(0, nVersions).filter(o => o.value > minimumUsers).map(o => o.key);
    }else{
        return [];
    }
};

export const arePropertyFiltersSame = (base, tagret) => {
    if(isArray(base) && isArray(tagret)){
        if(base.length === tagret.length){
            return base.every(o => {
                const targetIndex = tagret.findIndex(i => i.name === o.name);
                return targetIndex !== -1 && areArraysEqual(o.value, tagret[targetIndex].value)
            });
        }
        return false;
    }
    return false;
};